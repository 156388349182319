var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-tree-select", {
    staticStyle: { width: "100%" },
    attrs: {
      value: _vm.newCategory,
      "dropdown-style": { maxHeight: "400px", overflow: "auto" },
      "tree-data": _vm.categories,
      "tree-default-expand-all": ""
    },
    on: {
      change: function(newValue) {
        newValue !== _vm.oldCategory ? _vm.changeCategory(newValue) : null
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }