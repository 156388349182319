//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';

export default Vue.extend({
  computed: {
    ...mapGetters('category', ['categories']),

    codeId() {
      return this.params.data?.id;
    },
    pageId() {
      return this.$route.params.pageId;
    },
    oldCategory() {
      return this.params.data?.category_id || '';
    }
  },

  data() {
    return {
      newCategory: '',
      loading: false
    };
  },

  mounted() {
    this.newCategory = this.oldCategory || '';
  },

  methods: {
    ...mapActions('code', ['updateCode']),

    async changeCategory(value) {
      try {
        this.loading = true;
        this.newCategory = value;
        const data = await this.$s.api.page.editCodeById(this.pageId, this.codeId, {
          category_id: value
        });
        this.updateCode(data);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    }
  }
});
