var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-dropdown",
        { staticClass: "mb-sm" },
        [
          _c(
            "a-menu",
            { attrs: { slot: "overlay" }, slot: "overlay" },
            [
              _c(
                "a-menu-item",
                { key: "editCode", on: { click: _vm.goToCodeDetail } },
                [
                  _c("a-icon", { attrs: { type: "edit" } }),
                  _c("span", [_vm._v(" " + _vm._s(_vm.$t("Edit")) + " ")])
                ],
                1
              ),
              _c(
                "a-menu-item",
                {
                  key: "openCodePageEditor",
                  attrs: { disabled: !_vm.isPublishedStatus }
                },
                [
                  _c("a-icon", { attrs: { type: "code" } }),
                  _vm.ispublic
                    ? _c("span", { on: { click: _vm.openCodePageEditor } }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("project.grid.EditWebsite")) + " "
                        )
                      ])
                    : _c(
                        "a-popconfirm",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "ok-text": "OK",
                            disabled: !_vm.isPublishedStatus,
                            "cancel-text": _vm.$t("Cancel")
                          },
                          on: {
                            confirm: _vm.publicCodeAndOpenIframeReview,
                            cancel: function() {}
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "popconfirm-title",
                              attrs: { slot: "title" },
                              slot: "title"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "This code is private now, you need to change it to public to embed. Are you sure to change it to public?"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("code.grid.EditWebsite")) +
                                " "
                            )
                          ])
                        ]
                      )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "handleDuplicateCode" },
                [
                  _c(
                    "a-popconfirm",
                    {
                      attrs: {
                        placement: "left",
                        title: _vm.$t(
                          "Would you like to duplicate this code？"
                        ),
                        "ok-text": _vm.$t("Yes"),
                        "cancel-text": _vm.$t("No")
                      },
                      on: { confirm: _vm.handleDuplicateCode }
                    },
                    [
                      _c("a-icon", { attrs: { type: "copy" } }),
                      _c("span", [
                        _vm._v(" " + _vm._s(_vm.$t("Duplicate")) + " ")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                {
                  key: "handleCopyCodeOpening",
                  on: { click: _vm.handleCopyCodeOpening }
                },
                [
                  _c("a-icon", { attrs: { type: "copy" } }),
                  _c("span", [_vm._v(" " + _vm._s(_vm.$t("Copy")) + " ")])
                ],
                1
              ),
              _c(
                "a-menu-item",
                {
                  key: "handleMoveCodeOpening",
                  on: { click: _vm.handleMoveCodeOpening }
                },
                [
                  _c("a-icon", { attrs: { type: "drag" } }),
                  _c("span", [_vm._v(" " + _vm._s(_vm.$t("Move")) + " ")])
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "deleteCode" },
                [
                  _c(
                    "a-popconfirm",
                    {
                      attrs: {
                        placement: "left",
                        title: _vm.$t("Are you sure to delete this code"),
                        "ok-text": _vm.$t("Yes"),
                        "cancel-text": _vm.$t("No")
                      },
                      on: { confirm: _vm.handleDeleteCode }
                    },
                    [
                      _c("a-icon", { attrs: { type: "delete" } }),
                      _c("span", [_vm._v(" " + _vm._s(_vm.$t("Delete")) + " ")])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-button",
            [
              _vm._v(_vm._s(_vm.$t("Actions"))),
              _c("a-icon", { attrs: { type: "down" } })
            ],
            1
          )
        ],
        1
      ),
      _vm.choosePageModalVisible
        ? _c("choose-page-modal", {
            attrs: {
              "action-type": _vm.pageChoosingActionType,
              visible: _vm.choosePageModalVisible,
              projects: _vm.projectsAndPages
            },
            on: {
              copy: _vm.handleCopyCodes,
              move: _vm.handleMoveCodes,
              cancel: function($event) {
                _vm.choosePageModalVisible = false
              }
            }
          })
        : _vm._e(),
      _vm.codeEditorModalVisible
        ? _c("iframe-code-single", {
            attrs: { visible: _vm.codeEditorModalVisible, code: _vm.code },
            on: { cancel: _vm.cancelCodePageEditor }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }