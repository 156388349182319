//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable no-undef */
import Vue from 'vue';

export default Vue.extend({
  computed: {
    tags() {
      return this.params.data.tags;
    }
  }
});
