var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-switch", {
    attrs: {
      checked: _vm.ispublic,
      "checked-children": _vm.$t("Public"),
      "un-checked-children": _vm.$t("Private"),
      loading: _vm.loading
    },
    on: { change: _vm.changeStatus }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }