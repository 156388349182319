//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable no-undef */
import Vue from 'vue';
import { mapActions } from 'vuex';
import * as pubsub from '@/pubsub';

export default Vue.extend({
  data() {
    return {
      newTitle: '',
      loading: false
    };
  },

  computed: {
    originalTitle() {
      return this.params.data?.title;
    },
    codeId() {
      return this.params.data?.id;
    },
    pageId() {
      return this.$route.params.pageId;
    }
  },

  created() {
    this.newTitle = this.originalTitle;
  },
  mounted() {
    // Ref to https://www.ag-grid.com/javascript-data-grid/keyboard-navigation/#example-custom-navigation
    // custom keydown event for arrow left and right, because the default behavior of ag-grid is
    // move to next cell when press arrow left or right
    this.$refs.textarea.$el.addEventListener('keydown', e => {
      if (e.keyCode === 37 || e.keyCode === 39) {
        e.stopPropagation();
      }
    });
  },
  beforeUnmount() {
    this.$refs?.textarea?.$el?.removeEventListener('keydown', () => {});
  },

  methods: {
    ...mapActions('code', ['updateCode']),

    async updateTitle() {
      try {
        this.loading = true;
        const data = await this.$s.api.page.editCodeById(this.pageId, this.codeId, {
          title: this.newTitle
        });
        this.updateCode(data);
        if (
          data.status === 'PENDING' ||
          (data.status === 'PUBLISHED' && data.action === 'UPDATING')
        ) {
          const subscribePayload = {
            collectionName: 'CODE',
            method: 'GET',
            modelId: data.id
          };
          pubsub.subscribe(this.sockets, subscribePayload, code => {
            this.updateCode(code);
            pubsub.unSubscribe(this.sockets, subscribePayload);
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    }
  }
});
