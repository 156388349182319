//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import { mapGetters } from 'vuex';
import IframeCodeSingle from '../iframe-code-single/Index.vue';
import ChoosePageModal from '../modal/ChoosePageModal.vue';

export default Vue.extend({
  components: { IframeCodeSingle, ChoosePageModal },

  data() {
    return {
      loading: false,
      codeEditorModalVisible: false,
      choosePageModalVisible: false,
      pageChoosingActionType: ''
    };
  },

  computed: {
    ...mapGetters('user', ['userInfo']),
    ...mapGetters('page', ['pages']),
    ...mapGetters('project', ['projectById', 'projectsAndPages']),
    code() {
      return this.params.data;
    },
    codeType() {
      return this.params.data.code_type;
    },
    pathToEdit() {
      return this.codeType === 'voice_code'
        ? `/projects/${this.projectId}/pages/${this.pageId}/codes/voice-code/${this.codeId}`
        : `/projects/${this.projectId}/pages/${this.pageId}/codes/navi-code/${this.codeId}`;
    },
    codeId() {
      return this.params.data.id;
    },
    pageId() {
      return this.$route.params.pageId;
    },
    projectId() {
      return this.$route.params.projectId;
    },
    statusCode() {
      return this.params.data.status;
    },
    isPublishedStatus() {
      return this.params.data.status === 'PUBLISHED';
    },
    ispublic() {
      return this.params.data.ispublic;
    }
  },
  methods: {
    goToCodeDetail() {
      this.$router.push(this.pathToEdit);
    },
    handleDeleteCode() {
      this.params.context.componentParent.deleteCodes([this.code.id]);
    },
    handleDuplicateCode() {
      this.params.context.componentParent.duplicateCode(this.codeId);
    },
    openCodePageEditor() {
      this.codeEditorModalVisible = true;
    },
    cancelCodePageEditor() {
      this.codeEditorModalVisible = false;
    },
    async publicCodeAndOpenIframeReview() {
      try {
        this.loading = true;
        const input = { ispublic: true, status: this.statusCode };
        await this.$s.api.page.editCodeById(this.pageId, this.codeId, input);
        this.$notification.success({ message: this.$t('This code is public.') });
        this.codeEditorModalVisible = true;
      } catch (error) {
        this.$notification.error({ message: this.$t('This code public failed.') });
      } finally {
        this.loading = false;
      }
    },
    handleCopyCodeOpening() {
      this.choosePageModalVisible = true;
      this.pageChoosingActionType = 'copy';
    },
    handleMoveCodeOpening() {
      this.choosePageModalVisible = true;
      this.pageChoosingActionType = 'move';
    },
    handleCopyCodes({ selectedPageId, resolve, reject }) {
      this.params.context.componentParent.handleCopyCodes({
        selectedPageId,
        codeIds: [this.codeId],
        resolve,
        reject
      });
      this.choosePageModalVisible = false;
    },
    handleMoveCodes({ selectedPageId, resolve, reject }) {
      this.params.context.componentParent.handleMoveCodes({
        selectedPageId,
        codeIds: [this.codeId],
        resolve,
        reject
      });
      this.choosePageModalVisible = false;
    }
  }
});
