var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("router-link", { attrs: { to: _vm.linkTo } }, [
    _vm._v(" " + _vm._s(_vm._f("formatId")(_vm.codeId, "CO")) + " ")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }