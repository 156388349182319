//
//
//
//
//
//

/* eslint-disable no-undef */
import Vue from 'vue';

export default Vue.extend({
  computed: {
    codeId() {
      return this.params.data.id;
    },
    pageId() {
      return this.$route.params.pageId;
    },
    codeType() {
      return this.params.data.code_type;
    },
    linkTo() {
      const nameMapping = {
        voice_code: 'voice-code-detail',
        spot_code: 'navi-code-detail',
        navi_code: 'navi-code-detail',
        mix_code: 'navi-code-detail'
      };
      return {
        name: nameMapping[this.codeType],
        params: { pageId: this.pageId, codeId: this.codeId }
      };
    }
  }
});
