var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex", "align-item": "center" } },
    [
      _c("div", { staticStyle: { width: "100%" } }, [
        _c(
          "span",
          {
            staticClass:
              "ant-input-search ant-input-search-enter-button ant-input-group-wrapper"
          },
          [
            _c(
              "span",
              { staticClass: "ant-input-wrapper ant-input-group" },
              [
                _c("a-textarea", {
                  ref: "textarea",
                  attrs: { "auto-size": { minRows: 2, maxRows: 4 } },
                  model: {
                    value: _vm.newTitle,
                    callback: function($$v) {
                      _vm.newTitle = $$v
                    },
                    expression: "newTitle"
                  }
                }),
                _c(
                  "span",
                  { staticClass: "ant-input-group-addon" },
                  [
                    _vm.loading
                      ? _c("a-button", {
                          staticStyle: { padding: "0 6px" },
                          attrs: { type: "primary", loading: "" }
                        })
                      : _c(
                          "a-button",
                          {
                            staticStyle: { padding: "0 6px" },
                            attrs: {
                              type: "primary",
                              disabled: _vm.originalTitle === _vm.newTitle
                            },
                            on: {
                              click: function() {
                                _vm.originalTitle !== _vm.newTitle
                                  ? _vm.updateTitle()
                                  : null
                              }
                            }
                          },
                          [_vm._v(" OK ")]
                        )
                  ],
                  1
                )
              ],
              1
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }