//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import { mapActions } from 'vuex';

export default Vue.extend({
  data() {
    return {
      loading: false
    };
  },

  computed: {
    codeId() {
      return this.params.data.id;
    },
    pageId() {
      return this.$route.params.pageId;
    },
    ispublic() {
      return this.params.data.ispublic;
    }
  },

  methods: {
    ...mapActions('code', ['updateCode']),

    changeStatus(e) {
      this.updateCodeById({ ispublic: e });
    },
    async updateCodeById(input) {
      const successNotifyMessage = {
        true: this.$t('This code is public.'),
        false: this.$t('This code is private.')
      };
      const errorNotifyMessage = {
        true: this.$t('This code public failed.'),
        false: this.$t('This code private failed.')
      };
      try {
        this.loading = true;
        const code = await this.$s.api.page.editCodeById(this.pageId, this.codeId, input);
        this.updateCode(code);
        this.$notification.success({
          message: successNotifyMessage[input.ispublic]
        });
      } catch (error) {
        console.log(error);
        this.$notification.error({
          message: errorNotifyMessage[input.ispublic]
        });
      } finally {
        this.loading = false;
      }
    }
  }
});
